
export const ErrorPage = () => {

    return(
        <div className="container-fulid">
            <div className="row">
                <div className="col-12">
                    <h3> Something was wrong...</h3>
                </div>
            </div>
        </div>
    )
}